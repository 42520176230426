.page-single {
    &__welcome {
        h1 {
            background: linear-gradient(165deg, $white 38%, $white);
            -webkit-background-clip: text;
            font-weight: bolder;
            z-index: 2;
            font-size: 4.5rem;
            line-height: 1.2;
            @include media-breakpoint-down(sm) {
                font-size: calc(1.575rem + 3.9vw);
            }
            strong, .text-primary {
                background: linear-gradient(165deg, $primary 38%, #e2189c);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 700;
                @include media-breakpoint-down(sm) {
                    display: inline-block;
                }
            }
            p{
                line-height: 1.2;
            }
        }

        h2, h3, h5, h6, p {
            strong {
                font-weight: bold;
            }

            a {
                color: $primary;
                font-weight: bold;
                text-decoration: underline;
            }
        }

        a {
            color: $primary;
            font-weight: bold;
            text-decoration: underline;
        }

        h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4){
            @extend .display-2;
            font-weight: normal;
            line-height: 1.6;
        }
        h3:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-dark):not(.text-black) {
            @extend .display-3;
            font-weight: normal;
            line-height: 1.6;
        }

        h4:not(.display-1):not(.display-2):not(.display-3):not(.display-4),
        h5:not(.display-1):not(.display-2):not(.display-3):not(.display-4),
        h6:not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
            @extend .display-4;
        }

        p:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text) {
            font-size: 1.8rem;
            line-height: 2;
            &.news__el__abstract{
                font-size: 1.6rem;
                line-height: 1.8;
            }
            &.schedule__el__abstract{
                font-size: 1.4rem;
                line-height: 1.5;
            }
        }
        ul{
            padding-left: 18px;
            font-size: 1.8rem;
            line-height: 2;
            a {
                color: $primary;
                font-weight: bold;
                text-decoration: underline;
            }
        }

        .embed-responsive.page-single-main-image {
            @extend .embed-responsive-1by1;

            @include media-breakpoint-up(md) {
                &:before {
                    padding-top: 39%;
                }
            }

            @include media-breakpoint-up(xl) {
                &:before {
                    padding-top: 30%;
                }
            }

            @include media-breakpoint-up(xxl) {
                &:before {
                    padding-top: 22%;
                }
            }

            &:after {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(0deg, rgba(46, 40, 61, 1) 0%, rgba(46, 40, 61, 0) 100%);
                content: '';
                z-index: 0;
            }
        }
        .schedule__item__container{
            h3{
                line-height: 1.2;
            }
        }

        .thematic-area{
            p{
                font-size: 1.4rem;
                line-height: 1.5;
            }
        }
        .schedule__day__time-range__indicator{
            top: 11rem;
        }
        @include media-breakpoint-down(sm) {
            .filters__heading--schedule {
                top: 7rem;
            }
        }
        .hot-topic{
            p{
                font-size: 1.4rem;
                line-height: 1.5;
            }
        }
        .partner__item__container{
            h3{
                font-size: 1.8rem;
                font-weight: 700;
                line-height: 1.2;

                @include  media-breakpoint-down(sm){
                    font-size: calc(1.305rem + .66vw);
                }
            }
            p{
                font-size: 1.2rem;
                line-height: 1.5;

                @include media-breakpoint-up(lg) {
                    font-size: 1.4rem;
                }

                @include media-breakpoint-up(xxl) {
                    max-width: 30rem;
                }
            }
        }
    }
}

.social-share-wrapper{
    text-align: center;
    .social-share-container{
        display: inline-block;
        min-width: 200px;
    }
}

.organization-news-content{
    a,
    a > * {
        color: $primary!important;
    }
}

.bulletin-page{
    .block-grid{
        width: 100% !important;
        max-width: none!important;
    }
    #contentFooter{
        display: none;
    }
}

#simple-page{
    h1 {
        background: linear-gradient(165deg, $white 38%, $white);
        -webkit-background-clip: text;
        font-weight: bolder;
        z-index: 2;
        font-size: 4.5rem;
        line-height: 1.2;
        @include media-breakpoint-down(sm) {
            font-size: calc(1.575rem + 3.9vw);
        }
        strong, .text-primary {
            background: linear-gradient(165deg, $primary 38%, #e2189c);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 700;
            @include media-breakpoint-down(sm) {
                display: inline-block;
            }
        }
    }
    h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4){
        @extend .display-2;
        font-weight: normal;
        line-height: 1.6;
    }
    p:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text) {
        font-size: 1.8rem;
        line-height: 2;
    }
}
.tab-container{
    max-width: 1000px;
    margin: auto;
    div.nav-tabs{
        justify-content: center;
        a.nav-link{
            color: #222;
            font-size: 1.6rem;
            padding: 20px;
            border: 0;
            @include media-breakpoint-down(sm) {
                font-size: 1.2rem;
                padding: 10px 6px;
            }
            &.active{
                position: relative;
                border: 0;
                background-color: transparent;
                border-bottom: 6px solid $primary;
            }
            &:hover{
                border: none;
                border-bottom: 6px solid #f1f1f1;
            }
        }
    }
}
.page,
#simple-page{
    .rdueb-home-block{
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 70px;
        padding-bottom: 90px;
        @include media-breakpoint-down(sm) {
            padding: 15px;
        }
        &:before{
            content:'';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: rgba(0,0,0,0.2);
            z-index: 0;
        }
        .container{
            position: relative;
            z-index: 1;
        }
        .row{
            background: #fff;
            border-left: 18px solid $primary;
            padding: 60px;
            align-items: center;
            @include media-breakpoint-down(sm) {
                padding: 30px 20px;
            }
            .col-lg-6{
                position: relative;
                padding-left: 50px;
                @include media-breakpoint-down(sm) {
                    padding-left: 15px;
                }
                h2:not(.display-1):not(.display-2):not(.display-3):not(.display-4){
                    font-size: 28px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-top: 25px;
                    margin-bottom: 25px;
                }
                p.subtitle:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text){
                    font-size: 22px;
                    line-height: 31px;
                }
                p:not(.display-1):not(.display-2):not(.display-3):not(.display-4):not(.text-black):not(.card__text){
                    font-size: 16px;
                    line-height: 23px;
                }
                &.first-column{
                    padding-left: 0;
                    padding-right: 50px;
                    @include media-breakpoint-down(sm) {
                        padding-right: 15px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        width: 1px;
                        background: #d8d8d8;
                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
