@keyframes gradientPlaceholder {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

// SVG animation.
@keyframes svg-animation {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg)
    }
}

// Circle animation.
@keyframes circle-animation {
    0%,
    25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }

    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }

    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
}

// Pulse animation
@keyframes pulse {
    0% {
        opacity: 1;
    }

    80% {
        opacity: .3;
    }

    100% {
        opacity: 1;
    }
}

// Notification progress
@keyframes notificationProgress {
    from {
        transform: scaleX(1);
    }

    to {
        transform: scaleX(0);
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30%);
    }

    85% {
        transform: translateY(5%);
    }

    100% {
        transform: translateY(0%);
    }
}
