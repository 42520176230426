button {
    &:hover, &:active, &:focus {
        outline: none;
    }
}
.btn {
    &--rounded {
        border-radius: 3rem;
    }

    &--share,
    &--subscribe {
        &:before {
            display: inline-block;
            margin-right: 1rem;
            background-repeat: no-repeat;
            content: '';
        }

        @include media-breakpoint-up(lg) {
            min-width: 20rem;
        }
    }

    &--share {
        &:before {
            width: 1.5rem;
            height: 1.5rem;
            background-size: 1.5rem;
            background-position: center;
            background-image: url('../vectors/icon-share.svg');
        }
    }

    &--subscribe {
        &:before {
            width: 2rem;
            height: 2rem;
            background-size: 2rem;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../vectors/icon-ticket.svg');
            content: '';
        }

        &--dark {
            &:before {
                background-image: url('../vectors/icon-ticket-dark.svg');
            }
        }
    }

    &--opened {
        &:hover {
            background-color: $green;
            color: $white;
            border-color: $green !important;;
        }
    }

    &--subscribed {
        background-color: $green;
        transition: background .3s ease;

        &__text {
            transition: all .4s ease;

            &:before {
                position: relative;
                display: inline-block;
                margin-right: 1rem;
                background-size: 2rem;
                background-repeat: no-repeat;
                background-position: center;
                vertical-align: middle;
                content: '';
            }

            &--subscribed {
                &:before {
                    top: -2px;
                    width: 2rem;
                    height: 2rem;
                    background-image: url('../vectors/icon-check.svg');
                }
            }

            &--unsubscribe {
                opacity: 0;
                transform: translate(-50%, 100%);

                &:before {
                    width: 1.2rem;
                    height: 1.2rem;
                    background-image: url('../vectors/icon-close-dark.svg');
                }
            }
        }

        &:hover {
            background-color: rgba($dark, .07);

            .btn--confirmed__text--subscribed {
                opacity: 0;
                transform: translateY(-100%);
            }

            .btn--confirmed__text--unsubscribe {
                opacity: 1;
                transform: translate(-50%, -50%);
            }
        }
    }

    &--bookmark {
        &:before {
            display: inline-block;
            width: 2.2rem;
            height: 3rem;
            margin-right: 1.5rem;
            background-size: 2.2rem;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../vectors/icon-bookmark-add.svg');
            vertical-align: middle;
            content: '';
        }
    }

    &-primary--gradient {
        background-image: linear-gradient(165deg, $primary 8%, $accent 100%);
        border-radius: 29.5px;
        transition: background .4s ease;

        &:hover {
            background-image: linear-gradient(120deg, $primary 15%, $accent 100%);
        }
    }
}

.btn-navbar-login {
    margin-right: -2rem;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    background-image: url('../vectors/icon-user-dark.svg');
    background-repeat: no-repeat;
    background-size: 2rem;
    background-position: center;
    color: $dark;

    @include media-breakpoint-up(md) {
        padding-left: 5.5rem;
        padding-right: 3rem;
        background-position: left 3rem center;
    }


    &--eventbrite {
        padding: 0;
        width: 8.5rem;
        background-size: 6rem;
        background-image: url('../vectors/logo-eventbrite-color.svg');

        @include media-breakpoint-up(xl) {
            width: 13rem;
            background-size: 8rem;
        }

        @include media-breakpoint-up(md) {
            background-position: left 1.1rem center;
        }

        @include media-breakpoint-up(lg) {
            width: 10rem;
            background-position: center;
        }

        .login-eventbrite{
            font-size: 10px;
            text-align: center;
            display: block;
            margin-top: 13px;
            margin-right: 5px;
        }
    }

    &:hover {
        text-decoration: none;
        &:after {
            background-color: rgba($dark, .05);
        }
        .login-eventbrite{
            color: #000;
        }
    }

    &:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-left: 1px solid rgba($dark, .3);
        z-index: 0;
        transform: skew(10deg, 0deg);
        transition: background-color .3s ease;
        content: '';
    }
}

.btn-agenda {
    width: 7rem;
    height: 7rem;
    cursor: pointer;
    background-color: $white;
    box-shadow: 0 .8rem 2.5rem rgba($dark, .3);
    transition: background-color .3s ease;

    &:hover {
        background-color: darken($white, 2%);
    }

    &__icon {
        width: 2.5rem;

        &--close {
            width: 1.7rem;
            opacity: 0;
            transform: translateX(-50%) translateY(150%);
        }
    }
}


.tag-filter__tag {
    white-space: nowrap;
    padding: .7rem 1.5rem;
    background-color: $white;
    font-size: 1.2rem;
    border: 2px solid $color-border;
    border-radius: 2rem;
    box-shadow: 0 1px 5px rgba($dark, .08);
    transition: all .3s ease;

    &:hover,
    &:active,
    &:focus {
        outline: none;
        box-shadow: 0 1px 5px rgba($dark, .08);
    }

    @include media-breakpoint-up(md) {
        padding: 1rem 1.5rem;
    }

    @include media-breakpoint-up(xxl) {
        white-space: normal;
    }

    &--active-last {
        cursor: default !important;
    }

    &:not(.tag-filter__tag--active) {
        background-color: $light;
        border: 2px solid $light;
        box-shadow: none;
        opacity: .5;

        &:hover {
            box-shadow: none;
        }
    }
}

.category-color-dot {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
}

.btn--subscribed.welcome--participant__main-btn{
    max-width: 2em;
}

.navbar__side-menu__btn-search-reset {
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    background-image: url('../vectors/icon-close.svg');
    opacity: 0;
    visibility: hidden;
}
