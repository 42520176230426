@import "_variables";

/*$enable-responsive-font-sizes: true;*/

$font-family-sans-serif:
        // Safari for OS X and iOS (San Francisco)
        -apple-system,
            // Chrome < 56 for OS X (San Francisco)
        BlinkMacSystemFont,
            // Windows
        "Segoe UI",
            // Android
        "Roboto",
            // Basic web fallback
        "Helvetica Neue", Arial, sans-serif,
            // Emoji fonts
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$theme-colors: (
        primary: $color-primary,
        accent: $accent,
        secondary: $color-secondary,
        dark: $color-primary--dark,
        light: $light,
        black: $black,
        green: $green,
        smau-agrifood: $color-primary,
        smau-digital-trends: $color-focus__digital-trends,
        smau-ict: $color-primary,
        smau-industry: $color-focus__industry,
        smau-international: $color-focus__international,
        smau-mobile: $color-focus__mobile,
        smau-open-innovations: $color-focus__open-innovations,
        smau-trade-tourism: $color-focus__trade-tourism,
        smau-health: $color-focus__health,
);

$grid-breakpoints: (
        xxs: 0,
        xs: 320px, // was 0
        sm: 413px, // was 767px
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1610px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1440px
);

$embed-responsive-aspect-ratios: ((21 9), (16 9), (4 3), (3 4), (1 1), (3,2), (2 3), (6 7));

// Type display sizes
$display1-size: 4.5rem;
$display2-size: 3.2rem;
$display3-size: 2.6rem;
$display4-size: 1.8rem;

$box-shadow:  0 .5rem .8rem rgba($black, .12);
