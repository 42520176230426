.countdown {
    &__value {
        width: 100%;
        border-right: 1px solid $white;

        @include media-breakpoint-up(md) {
            max-width: 10rem;
        }

        [class*=__] {
            min-height: 6rem;
        }
    }
}
