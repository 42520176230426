.welcome--participant__recent-img {
    max-width: 22rem;
}

.partner-single {
    .welcome--participant {
        &:before {
            @include media-breakpoint-up(lg) {
                width: 37%;
            }
        }

        &__img{
            max-height: 6rem;
        }

        &__website {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.section-products {
    max-width: 144rem;
}
.partner{
    &-main-el {
        &--faded {
            transform: translateY(-10px);
            opacity: 0;
        }
    }
}
