.filters__heading--scrollable {
    position: relative;

    &:after {
        position: absolute;
        display: block;
        width: 4rem;
        height: 6.5rem;
        top: 0;
        right: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        content: '';
    }

    .nav-tabs {
        display: block;
        white-space: nowrap;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;

        @include media-breakpoint-up(lg) {
            display: flex;
            white-space: normal;
            overflow: initial;
        }

        a {
            display: inline-block;
        }
    }
}

.navbar__side-menu__content {
   .tab-pane {
       padding: 0 .6rem; // avoid show card shadow
       transform: translate3d(0,0,0);
   }
}

.navbar__side-menu__content__search-content__nav-tabs {
    position: sticky;
    display: block;
    width: 100%;
    top: -6.6rem;
    margin-top: -2.55rem;
    padding-right: 5rem;
    white-space: nowrap;
    overflow: auto;
    z-index: 2;

   @include media-breakpoint-up(md) {
       top: -7rem;
       margin-top: -2.2rem;
       padding: 2.2rem 0;
   }

    .embed-responsive {
        @include media-breakpoint-down(md) {
            max-width: 14rem;
        }
    }

    .nav-item {
        display: inline-block;
        margin-left: 1rem;

        &:first-child {
            margin-left: 0;
        }
    }

    .nav-link {
        background-color: rgba($dark, .1);
        font-size: $display5-size;
        color: $white;
        border: none;
        border-radius: 2rem;
        @extend .ls-2;
        transition: shadow .3s ease;

        .badge {
            background-color: rgba($dark, .2);
            color: $white;
            text-align: center;
            border-radius: 2rem;
            transition: none;
        }
    }

    .nav-link.active {
        color: $dark;
        background-color: $white;
        @extend .shadow;

        @supports (backdrop-filter: blur(20px)) {
            backdrop-filter: blur(20px);
            background-color: rgba($white, .8);
        }

        .badge {
            background-color: $dark;
            color: $white;
        }
    }

}
