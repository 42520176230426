.links-within-shape {
    position: relative;
    padding: 2rem .5rem 1.5rem;

    @include media-breakpoint-up(lg) {
        position: absolute;
        display: inline-block;
        right: 0;
        top: 100%;
        background: none;
        padding: 7rem 10% 12% 7rem;
        transform: rotate(17deg) translateY(-85%);
        z-index: 1;
    }

    @include media-breakpoint-up(xxl) {
        padding: 7rem 10% 10% 7rem;
    }

    &:before,
    &:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &:before {
        background-color: $dark;
        transform: translateX(25%) translateY(25%) scale(1.5) rotate(25deg);

        @include media-breakpoint-up(lg) {
            content: '';
        }
    }

    &:after {
        background-color: $primary;
        background-image: linear-gradient(165deg, $primary 8%, #E2189C 100%);
        content: '';
    }

    &__list {
        @include media-breakpoint-up(lg) {
            transform: rotate(-17deg);
        }

        li {
            float: left;
            vertical-align: middle;
            a{
                &:before {
                    position: relative;
                    display: inline-block;
                    width: 1.2rem;
                    height: 1.2rem;
                    top: 1px;
                    margin-right: .2rem;
                    background-image: url('../vectors/icon-plus.svg');
                    background-repeat: no-repeat;
                    background-size: 100%;
                    background-position: center;
                    content: '';

                    @include media-breakpoint-up(sm) {
                        margin-right: .7rem;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                float: none;
            }
        }
    }
}

.welcome__shape {
    width: 101%; // fix a webkit rendering issue
    bottom: -1px;
}

#main-homepage{
    h2{
        font-size: 4.5rem;
        line-height: 1.2;
        font-weight: 700;
        strong{
            background: linear-gradient(165deg,#ed1c24 38%,#e2189c);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 700;
        }
        @media (max-width: 767.98px){
            font-size: calc(1.575rem + 3.9vw);
        }

    }
}

