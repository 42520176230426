.speaker__item__container {
    .embed-responsive-item {
        transition: transform .5s ease;
    }

    &:hover {
        .embed-responsive-item {
            transform: scale(1.05);
        }
    }

    .embed-responsive {
        border-radius: 1rem;
        transform: translate3d(0, 0, 0);

        @include media-breakpoint-up(md) {
            border-radius: 1.5rem;
        }
    }
}
