.modal:after {
    backdrop-filter: blur(3px);
}

.modal-content {
    border-radius: 1.4rem;
}

.progress-bar {
    background: $primary; // fallback
    background: linear-gradient(90deg, $secondary 0%, $primary 20%, rgba(226,24,156,1) 100%);
}

.modal {
    &-header {
        border: none;
    }

    &__face {
        border-radius: 1rem;
        max-width: 10rem;

        @include media-breakpoint-up(md) {
            max-width: none;
        }
    }

    &-dialog {
        max-width: 76.8rem;
    }
}

.close {
    font-size: 3rem;

    &:active, &:focus {
        outline: none;
        box-shadow: none;
    }
}
