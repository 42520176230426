.card {
    &--partner {
        @include media-breakpoint-up(xxl) {
            flex: 0 0 20%;
            max-width: 20%;
        }

        &__img {
            max-width: 14rem;
            max-height: 14rem;
        }

        .card__image--scale {
            max-width: 15rem;

            img{
                max-height: 9rem;
            }

            @include media-breakpoint-down(md) {
                img{
                    max-height: 8rem;
                }
            }

            @include media-breakpoint-up(sm) {
                max-width: 90%;
                margin: 0;

                img {
                    transition: transform .5s ease
                }

                &:hover {
                    img {
                        transform: scale(.9);
                    }
                }
            }
        }
    }

    &__image {
        &--scale {
            border-radius: 3px;
            transition: box-shadow .3s ease, transform .5s ease;

            @media (pointer: coarse) {
                &:hover,
                &:active,
                &:focus {
                    transform: scale(.95);
                }
            }

            @include media-breakpoint-up(md) {
                &:hover {
                    transform: scale(1.05);
                    box-shadow: 0 2px 5px rgba($dark, .15), 0 10px 15px rgba($dark, .25);

                    .card__image--scale__overlay {
                        &__bg {
                            transform: rotate(10deg) translateX(-90%) translateY(-15%) scale(2);
                        }
                    }
                }
            }

            &__overlay {
                width: 2.5rem;

                &__bg {
                    transform: rotate(7deg) translateX(-85%) translateY(-15%) scale(1.5);
                    transform-origin: left;
                    transition: transform .7s ease;
                    z-index: -1;
                }
            }
        }

        &--has-icon {
            &:hover {
                &:after {
                    transform: translate(45%, 75%);
                }
            }

            &:after {
                position: absolute;
                display: block;
                width: 1.5rem;
                height: 1.5rem;
                top: .7rem;
                left: .7rem;
                background-image: url('../vectors/icon-plus.svg');
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;
                z-index: 2;
                transform-origin: center;
                transition: transform .7s ease;
                content: '';
            }
        }
    }

    &__image {
        &__text {
            &:before {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 13%, rgba(0, 0, 0, 0.55) 100%);
                z-index: 0;
                content: '';
            }
        }
    }

    &__body {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &__text {
        font-size: 1.2rem;

        @include media-breakpoint-up(lg) {
            font-size: 1.4rem;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 30rem;
        }
    }

    &__footer {
        &__avatar {
            img {
                width: 4rem;
                height: 4rem;
            }
        }
    }

    .display-3 {
        @include media-breakpoint-down(xl) {
            font-size: 2.2rem;
        }
    }
}
.partner__item{
    display: none;
    &--visible {
        display: block;
    }
}

.thematic-area-list-image{
    height: 180px;
    object-fit: cover;
    border-radius: 1em;
}
