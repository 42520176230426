#main-login-register{
    &:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 30%;
        top: 0;
        left: 0;
        background-color: $dark;
        background-image: url(../images/bg-login-register.jpg);
        background-size: cover;
        background-position: bottom;
        content: '';

        @include media-breakpoint-up(md) {
            height: 40%;
        }

        @include media-breakpoint-up(lg) {
            min-height: 12rem;
        }
    }
}
