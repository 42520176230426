.email-template{
  a{
    color: #ee2e24;
    text-decoration: underline;
  }
  a,
  strong,
  span,
  p{
    font-size: 14px;
    line-height: 20px;
  }
  ul{
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    li{
      padding: 5px;
      margin-left: 15px;
    }
  }
  .border-row{
    width: 270px;
    margin: 3em auto;
    border-left: 5px solid #ee2e24;
    padding: 0 0 0 15px;
    a{
      color: #15c;
      font-weight: bold;
    }
  }
  &.footer-email{
    a{
      font-weight: bold;
    }
  }
}