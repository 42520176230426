.bottom-navigation {
    .open {
        .bottom-navigation__title:after {
           @include media-breakpoint-down(md) {
               content: '-';
           }
        }
    }

    &__title {
        &:after {
            display: inline-block;
            margin-left: .7rem;
            opacity: .5;
            content: '+';

            @include media-breakpoint-up(md) {
                content: none;
            }
        }
    }

    &__input-newsletter {
        border-width: 2px !important;
    }

    &__list {
        display: none;

        @include media-breakpoint-up(md) {
            display: block !important; // avoid slideToggle effects on resize
        }
    }

    a {
        transition: opacity .3s ease;

        &:hover {
            opacity: .7;
        }
    }
}
