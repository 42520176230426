.default-search {
    &__input {
        padding: 1.5rem 7rem 1.5rem 6rem;
        background-color: $white;
        font-weight: 600;
        background-image: url('../vectors/icon-search-dark.svg');
        background-repeat: no-repeat;
        background-size: 2rem;
        background-position: center left 2rem;
        box-shadow: 0 5px 10px 0 rgba($dark, .03);
        border: 1px solid $color-border;;
        border-radius: 1.2rem;
        transition: box-shadow .6s ease;

        @include media-breakpoint-up(md) {
            padding: 2rem 7rem 2rem 6rem;
            background-size: 2.6rem;
            border-radius: 1.7rem;
        }

        &:hover,
        &:active,
        &:focus {
            box-shadow: 0 7px 30px 0 rgba($dark, .1);
            outline: none;
        }
    }

    &__btn-reset {
        display: block;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 40%;
        background-image: url('../vectors/icon-close-dark.svg');
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        transition: all .2s ease;
        pointer-events: none;

        &--revealed {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
            pointer-events: all;
        }
    }
}
.form,
.ajax-form {
    &__heading {
        &__description {
            max-width: 40rem;
            margin: auto;
        }
    }

    &__switcher {
        &__link {
            border-bottom: 4px solid transparent;
            transition: border .3s ease;

            &:hover {
                border-color: $light;
            }

            &--active {
                border-color: $primary;

                &:hover {
                    border-color: $primary
                }
            }
        }
    }

    select {
        appearance: none;
        padding: 1rem 4rem 1rem 1.5rem;
        background-color: $white;
        background-image: url('../vectors/icon-caret.svg');
        background-repeat: no-repeat;
        background-size: 1rem;
        background-position: center right 1.5rem;
        border: 1px solid rgba($dark, .2);
        font-weight: bold;
        box-shadow: 0 .5rem 1rem rgba($dark, .05);
        outline: none;
        cursor: pointer;
        transition: box-shadow .3s ease;
        font-size: 1.4rem;
        height: auto;
        max-width: 100%;
        &:hover {
            box-shadow: 0 .7rem 1rem rgba($dark, .08);
        }
    }

    label {
        transition: .5s ease;
    }

    textarea {
        //background-color: rgba($dark, .01);
        border: 1px solid rgba($dark, .3);;
        font-size: 1.4rem;
        &:hover,
        &:active,
        &:focus {
            outline: none;
        }
    }

    .form__input {
        display: flex;
        flex-flow: column-reverse;
        margin-bottom: 1em;
        padding-left: 4rem;

        &--user,
        &--email,
        &--password,
        &--company,
        &--url,
        &--linkedin,
        &--facebook,
        &--instagram,
        &--youtube {
            &:before {
                position: absolute;
                display: block;
                width: 2rem;
                height: 2rem;
                bottom: 0;
                left: 0;
                margin-right: 1rem;
                background-image: url('../vectors/icon-mail-dark.svg');
                background-repeat: no-repeat;
                background-size: 100%;
                transform: translateY(-50%);
                opacity: .8;
                content: '';
            }
        }

        &--user {
            &:before {
                background-image: url('../vectors/icon-user-dark.svg');
            }
        }

        &--email {
            &:before {
                background-image: url('../vectors/icon-mail-dark.svg');
            }
        }

        &--password {
            &:before {
                background-size: 100% 100%;
                background-position: center;
                background-image: url('../vectors/icon-key-dark.svg');
            }
        }

        &--company {
            &:before {
                background-size: 100% 100%;
                background-position: center;
                background-image: url('../vectors/icon-company-dark.svg');
            }
        }

        &--url {
            &:before {
                background-size: 100% 100%;
                background-position: center;
                background-image: url('../vectors/icon-world-dark.svg');
            }
        }

        &--linkedin {
            &:before {
                background-size: 100% 100%;
                background-position: center;
                background-image: url('../vectors/icon-linkedin-dark.svg');
            }
        }

        &--facebook {
            &:before {
                background-size: 100% 100%;
                background-position: center;
                background-image: url('../vectors/icon-facebook-dark.svg');
            }
        }

        &--instagram {
            &:before {
                background-size: 100% 100%;
                background-position: center;
                background-image: url('../vectors/icon-instagram-dark.svg');
            }
        }

        &--youtube {
            &:before {
                background-size: 100% 100%;
                background-position: center;
                background-image: url('../vectors/icon-youtube-dark.svg');
            }
        }

        &--submit {
            min-width: 20rem;
            padding: 3rem 2rem;
            background-image: url('../vectors/icon-arrow-right.svg');
            background-repeat: no-repeat;
            background-position: center right 1.7rem;
            background-size: 2rem;
            transition: background-position .4s ease;

            &:hover {
                background-position: center right 1.3rem;
            }
        }
    }
    .form__input,
    .formbuilder-row .form-group{
        display: flex;
        flex-flow: column-reverse;
        margin-bottom: 1em;
        label{
            font-size: 1.2rem;
            font-weight: 700!important;
            text-transform: uppercase!important;
        }
        label, input {
            transition: all .7s ease;
            touch-action: manipulation;
        }

        input {
            font-size: 1.2em;
            border: 0;
            border-bottom: 1px solid rgba($dark, .3);
            -webkit-appearance: none;
            border-radius: 0;
            padding: 0;
            cursor: text;
            padding-bottom: .8rem;
        }

        input:focus {
            outline: 0;
            box-shadow: none;
        }

        label {
            margin-bottom: 0;
            line-height: 2;
        }

        input:placeholder-shown + label {
            cursor: text;
            max-width: 66.66%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            //opacity: .5;
            transform-origin: left bottom;
            transform: translate(0, 2.6rem) scale(1.2);
        }

        ::-webkit-input-placeholder {
            opacity: 0;
            transition: inherit;
        }

        input:focus::-webkit-input-placeholder {
            opacity: 0;
        }

        input:not(:placeholder-shown) + label,
        input:focus + label {
            opacity: .6;
            transform: translate(0, 0) scale(1);
            transform-origin: left bottom;
            cursor: pointer;
        }

        button[type="submit"]{
            min-width: 20rem;
            padding: 1.5rem 2rem;
            background-image: url('../vectors/icon-arrow-right.svg');
            background-repeat: no-repeat;
            background-position: center right 1.7rem;
            background-size: 2rem;
            transition: background-position .4s ease;
            background-color: $secondary;
            border-color: $secondary;
            color: #fff!important;
            font-weight: 700!important;
            text-transform: uppercase!important;
            border-radius: 3rem;
            letter-spacing: 2px;
            font-size: 1.2rem;
            max-width: 200px;
            margin: 4rem auto;
            &:hover {
                background-position: center right 1.3rem;
            }
        }
        .privacy-checkbox + legend {
            display: none;
        }
        .custom-radio,
        .custom-checkbox{
            .custom-control-label{
                opacity: 1;
                a{
                    color: $primary;
                    opacity: 1;
                    text-decoration: underline;
                    text-transform: none;
                }
            }
        }
        small.text-muted{
            order: -1;
            margin-top: 1rem;
        }
    }

    @-moz-document url-prefix() {
        input::placeholder {
            color: white;
        }
    }
    .help-text{
        font-size: 80%;
        font-weight: 400;
        color: #6c757d!important;
        margin-bottom: 2.2rem;
    }
}
.form-builder-wrapper{
    max-width: 1440px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @include media-breakpoint-down(md) {
        max-width: 960px;
    }
    @include media-breakpoint-down(sm) {
        max-width: 720px;
    }
    @include media-breakpoint-down(xs) {
        max-width: 540px;
    }
    .ajax-form{
        > .row{
            flex-direction: column;
        }
    }
    .formbuilder-row.default{
        padding: 0;
        label{
           .invalid-feedback{
               display: block!important;
               .badge-danger{
                   display: none;
               }
               .form-error-message{
                   font-size: 1.4rem;
                   color: $primary;
                   font-weight: 700!important;
               }
           }
        }
        .form-control.is-invalid{
            background-image: none;
            border-color: rgba(46,40,61,.3);
        }
        .custom-control-input.is-invalid~.custom-control-label:before{
            border-color: rgba(46,40,61,.5);
        }
        .invalid-feedback{
            order: -2;
            .badge-danger{
                display: none;
            }
            .form-error-message{
                font-size: 1.4rem;
                color: $primary;
                font-weight: 700!important;
            }
        }
    }

    .formbuilder-row.default label.checkbox-custom.custom-control-label.required .invalid-feedback {
        display: none!important;
    }
}

.input-brand {
    position: relative;
    height: auto;
    top: -8px;

    label,
    input {
        margin: 0;
        font-size: $display5-size;
        letter-spacing: 0;
    }

    input:placeholder-shown + label {
        transform: translateY(1.125rem) scale(1);
    }

    input:not(:placeholder-shown) + label,
    input:focus + label {
        opacity: 0;
        transform: translate(0, 0) scale(1);
        cursor: pointer;
    }
}

.login-social-row {
    margin-top: 1rem;

    &__btn {
        width: calc(33.333% - .5rem);
        background-color: $white;
        transition: all .3s ease;

        &:hover {
            box-shadow: 0 .5rem 1rem rgba($dark, .05);
        }

        &__icon {
            max-width: 2rem;
            max-height: 2rem;
            margin-right: 1rem;


            &--linkedin {
                top: -.3rem;
            }
        }
    }
}
#visitor_profile_edit_businessCategory,
label[aria-describedby="visitor_profile_edit_businessCategory_help"],
#visitor_profile_edit_businessCategory_help,
label[aria-describedby="visitor_profile_edit_businessIndustry_help"],
#visitor_profile_edit_businessIndustry_help,
#visitor_profile_edit_businessIndustryOther,
label[for="visitor_profile_edit_businessIndustryOther"],
#visitor_profile_edit_businessIndustryOther_help,
#visitor_profile_edit_businessIndustry {
    display: none;
}

form {
    ul {
        padding-left: 0;

        li {
            list-style-type: none;
            font-weight: bold;
        }
    }
}

.option-label {
    position: relative;
    display: inline-block;
    text-align: left;

    label {
        margin-left: -7px;
    }

    &.first-el {
        label {
            margin-left: 0;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: -22px;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        opacity: .9;
        border: 0 solid transparent;
        background: linear-gradient(to bottom, #f5f5f5 0, #f9f9f9 100%);
    }
}
.custom-control.custom-radio{
    padding-left: 0;
    margin-top: 1.5rem;
    label{
        &:before{
            display: none;
        }
    }
}
.custom-control.custom-checkbox,
.form-check {
    position: relative;
    margin-top: 1.5rem;
    padding-left: 0;

    input[type="checkbox"] {
        position: absolute;
        z-index: 1;
        opacity: 0;

        &:checked {
            & + label:before {
                background-color: $secondary;
                border: 2px solid $secondary;
            }

            & + label:after {
                opacity: 1;
                z-index: 0;
            }
        }
    }

    label {
        cursor: pointer;
        margin-left: 0!important;
        position: relative;
        padding-left: 3.6rem;

        &:before, &:after {
            position: absolute;
            display: block;
            cursor: pointer;
            content: '';
        }

        &:before {
            width: 1.8rem;
            height: 1.8rem;
            top: 11px;
            left: 0;
            margin-top: -.9rem;
            background-color: $light;
            border: 2px solid rgba($dark, .5);
            border-radius: 3px;
        }

        &:after {
            width: 7px;
            height: 12px;
            top: 3px;
            left: 6px;
            border: solid white;
            border-width: 0 .25rem .25rem 0;
            transform: rotate(45deg);
            opacity: 0;
            z-index: -1;
            transition: background-color .3s ease;
        }
    }
    &.hide-block{
        display: inline-block;
        input[type=checkbox]{
            position: absolute;
            top: 0;
            left: 0;
            margin-left: 0;
        }
    }
}

input[type="radio"] {
    position: absolute;
    z-index: 0;
    opacity: 1;

    & + label {
        padding-left: 4rem;
        cursor: pointer;
    }

    &:checked {
        &:after {
            transform: scale(1);
        }
    }

    &:before,
    &:after {
        position: absolute;
        display: block;
        content: '';
        visibility: visible;
    }

    &:before {
        width: 1.8rem;
        height: 1.8rem;
        top: -1px;
        left: -1px;
        background-color: $white;
        border: 2px solid rgba($dark, .6);
        border-radius: 50%;
        z-index: 1;
        pointer-events: none;
    }

    &:after {
        width: .8rem;
        height: .8rem;
        top: 4px;
        left: 4px;
        background-color: $secondary;
        border-radius: 50%;
        z-index: 1;
        transform: scale(0);
        transition: transform .3s ease;
    }
}

.form-group{
    .g-recaptcha{
        margin-top: 2.5rem;
    }
}


