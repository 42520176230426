.display {
    &-1 {
        .text-primary {
            background: linear-gradient(165deg, $primary 38%,#e2189c);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        @include media-breakpoint-down(sm) {
            font-size: calc(1.575rem + 5vw);
        }
    }

    &-2 {
        @include media-breakpoint-down(sm) {
            font-size: calc(1.445rem + 2.34vw)
        }
    }

    &-3 {
        @include media-breakpoint-down(sm) {
            font-size: calc(1.385rem + 1.62vw)
        }
    }

    &-4 {
        @include media-breakpoint-down(sm) {
            font-size: calc(1.305rem + .66vw)
        }
    }

    &-5 {
        font-size: $display5-size;
    }

    &-initial {
        font-size: $display-initial-size !important;
    }
}

.lh {
    &-0 {
        line-height: 0;
    }

    &-1 {
        line-height: 1;
    }

    &-2 {
        line-height: 1.2;
    }

    &-3 {
        line-height: 1.4;
    }

    &-4 {
        line-height: 1.6;
    }

    &-5 {
        line-height: 2;
    }
}


.ls {
    &-1 {
        letter-spacing: 1px;
    }

    &-2 {
        letter-spacing: 2px;
    }

    &-3 {
        letter-spacing: 3px;
    }

    &-4 {
        letter-spacing: 4px;
    }

    &-5 {
        letter-spacing: 5px;
    }
}

.text-faded {
    opacity: .7;
}
