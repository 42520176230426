// theme colors
$color-primary: #ED1C24 !default;
$color-secondary: #344AF0 !default;
$color-primary--dark: #2E283D !default;
$accent: #E2189C !default;
$light: #F5F5F5;
$white: #FFFFFF;
$green: #69C881;

$primary: $color-primary;
$secondary: $color-secondary;
$dark: $color-primary--dark;
$black: #222222;
$color-border: #D9D9D9;

// Smau focus
$color-focus__industry: #4891CD;
$color-focus__digital-trends: #29B473;
$color-focus__open-innovations: #1AB795;
$color-focus__mobile: #F6921E;
$color-focus__trade-tourism: #146E96;
$color-focus__international: #0F9EA5;
$color-focus__health: #ffd500;

// social colors
$facebook: #305991 !default;
$twitter: #25A8D2 !default;
$pinterest: #F40024 !default;
$instagram: #863BB3 !default;
$youtube: #CD201F !default;
$eventbrite: #F05537 !default;

// typography
$display-initial-size: 1.4rem;
$display5-size: 1.2rem;

// DOM elements
$navbar-height-lg: 7rem;
$navbar-height-sm: 6.2rem;
