.range-slider-container * {
    &::selection {
        background: transparent;
    }
}

.range-slider {
    position: relative;
    width: 100%;
    padding: 1rem 0;
    overflow: hidden;

    .steps-container__step {
        &__indicator {
            position: relative;
            padding: 3px 10px;
            font-size: 1rem;
            border-radius: 20px;
            box-shadow: 0 .2rem .6rem rgba($dark, .2);
            cursor: pointer;
            transition: all .4s ease;
            text-align: center;
            align-self: center;

            @include media-breakpoint-up(md) {
                font-size: smaller;
            }

            @include media-breakpoint-down(xs) {
                padding: 3px;
                font-size: 0.9rem;
            }

            &:after {
                position: absolute;
                width: 0;
                height: 0;
                bottom: -.5rem;
                left: 50%;
                margin-left: -.6rem;
                border-style: solid;
                border-width: .5rem .6rem 0 .6rem;
                border-color: transparent;
                content: '';
                transition: border .4s ease;
            }
        }

        &--active {
            .steps-container__step__indicator {
                color: $white;
                background: $secondary;
                animation: bounce .5s ease;

                &:after {
                    border-color: $secondary transparent transparent transparent;
                }
            }
        }
    }

    &__bar {
        position: relative;
        width: 100%;
        height: 1.5rem;
        margin-top: 1.5rem;
        background: linear-gradient(90deg, rgba(226, 24, 156, 1) 0%, $primary 70%, $secondary 100%);
        border-radius: 2px;
        box-shadow: 0 .2rem .6rem rgba($dark, .2);
        transform-origin: left;
        cursor: ew-resize;
        transform: scaleX(0);
    }
}
