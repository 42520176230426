.slider {
    background-color: $dark;

    .swiper-pagination {
        .swiper-pagination-bullet {
            margin-right: 1rem;
            background: lighten($dark, 60%);

            &:hover,
            &:active,
            &:focus {
                outline: none;
            }

            &-active {
                background: $white;
            }
        }
    }

    &--welcome {
        &__pagination {
            width: 83.33333%;
            bottom: 10rem;

            @include media-breakpoint-up(lg) {
                bottom: 4rem;
                left: 0;
            }

            @include media-breakpoint-up(xxl) {
                bottom: 7%;
                left: 0;
            }

        }
    }

    &__slide {
        &:before {
            position: relative;
            display: block;
            width: 100%;
            padding-top: 50rem;
            content: '';

            @include media-breakpoint-up(lg) {
                padding-top: 47.36%; // 19:9 aspect
            }

            @media (min-width: 1113px) and (max-height: 768px) {
                padding-top: 42%; // 21:9 aspect
            }

            @media (min-aspect-ratio: 16/9) and (min-width: 1700px) {
                padding-top: 42%; // 21:9 aspect
            }
        }

        &:after {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba($dark, .6);
            z-index: 0;
            content: '';
        }

        &__content {
            padding-bottom: 9rem;

            @media (min-aspect-ratio: 19/9), (min-width: 1800px) {
                padding-bottom: 7%;
            }

            &__title {
                @include media-breakpoint-up(xl) {
                    &.display-1 {
                        font-size: 6.5rem;
                    }
                }
            }

            &__paragraph {
                max-width: 45rem;
            }
        }
    }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next,
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    display: none;
    width: 4.5rem;
    height: 4.5rem;
    top: 50%;
    background-size: 4.5rem;
    background-position: center;
    opacity: .7;
    transition: opacity .3s ease, transform .3s ease;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    @media (pointer: coarse) {
        display: none;
    }

    &:hover {
        opacity: 1;
    }

    &:active {
        transform: scale(.8);
    }

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 2rem;
    background-image: url('../vectors/arrow-right.svg');
    transform-origin: right;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 2rem;
    background-image: url('../vectors/arrow-left.svg');
    transform-origin: left;
}

#main-homepage {
    .slider--logos,
    .slider--faces {
        &__slide {
            img {
                filter: grayscale(100%);
                transition: filter .4s ease;
            }
        }
    }
}

.slider--logos {
    img {
        max-width: 16rem;
        max-height: 6rem;
        min-height: 5em;
        object-fit: contain;
        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }
}

.slider--special-events,
.slider--live-shows {
    .swiper-slide {
        background-color: #fff;
        transition: box-shadow .4s ease;

        p {
            font-size: 1.2em;

            @include media-breakpoint-up(md) {
                font-size: $display4-size;
            }

            @include media-breakpoint-up(xl) {
                font-size: 1.5em;
            }
        }
    }
}

.slider--roadshow {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8.33333%;

    @include media-breakpoint-up(md) {
        padding: 0;
    }

    @media (min-width: 1440px) {
        padding: 0;
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            background-color: $dark;
            opacity: .3;

            &-active {
                background-color: $primary;
                opacity: 1;
            }
        }
    }

    .swiper-slide {
        &:hover {
            .embed-responsive-item img {
                transform: scale(1.07);
            }

            .swiper-slide__cta {
                opacity: 1;
                transform: translateY(0%);
            }
        }

        &__cta {
            opacity: 0;
            transform: translateY(50%);
            transition: .5s all ease;
        }

        .embed-responsive {
            max-width: 25rem;
            margin-left: auto;
            margin-right: auto;
            border-radius: 1.5rem;
            transform: translate3d(0, 0, 0);

            @include media-breakpoint-up(md) {
                max-width: 20rem;
            }

            &-item {
                &:before {
                    position: absolute;
                    display: block;
                    top: 1rem;
                    left: 50%;
                    width: 13rem;
                    height: 4rem;
                    margin-left: -6.5rem;
                    background-image: url('../vectors/logo-smau.svg');
                    background-repeat: no-repeat;
                    background-size: 100%;
                    background-position: center;
                    z-index: 2;
                    content: '';
                }

                img {
                    transition: transform .8s ease;
                }
            }
        }
    }
}

.slider--success-stories {
    &__img {
        max-width: 100%;
        max-height: 100%;
    }
}
