.event {
    .shadow-lg {
        box-shadow: 0 2px 143px 0 rgba(0, 0, 0, 0.11) !important;
    }

    &__info-block {
        background-color: rgba($dark, .07);
        border-radius: 4px;

        @include media-breakpoint-up(md) {
            margin-left: 1rem;

            &:first-child {
                margin-left: 0;
            }
        }


        &:before {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            content: '';
        }

        &--calendar {
            &:before {
                background-image: url("../vectors/icon-calendar.svg");
            }
        }

        &--time {
            &:before {
                background-image: url("../vectors/icon-clock.svg");
            }
        }

        &--location {
            &:before {
                background-image: url("../vectors/icon-location.svg");
            }
        }
    }

    .card {
        border-radius: 5px;
        margin-top: -5rem;

        @include media-breakpoint-up(lg) {
            margin-top: -15rem;
        }

        .btn--rounded {
            min-width: 100%;

            @include media-breakpoint-up(md) {
                min-width: 22rem;
            }
        }
    }

    &__section-partecipant {
        &:before {
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(222deg, #E6376A 0%, $primary 100%);
            z-index: 0;
            transform: skew(0deg, -3deg);
            content: '';
        }
        &.special-event-speaker{
            &:before {
                background-image: linear-gradient(222deg, #007bff 0%, #6f42c1 100%);
            }
        }
    }

    &__col-sticky {
        position: sticky;
        top: 10rem;
    }

    aside {
        .img-fluid {
            max-width: 30rem;

            @include media-breakpoint-up(lg) {
                max-width: 100%;
            }
        }

        .embed-responsive-1by1 {
            border-radius: 10px;
            max-width: 14rem;
        }
    }
}

#main-workshop {
    .card {
        @include media-breakpoint-between(lg, xl) {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.streaming-bar {
    position: sticky;
    box-shadow: 0 5px 34px 0 rgba($dark, .50);
    opacity: 1;
    visibility: visible;
    transition: all 1s ease;

    @supports (position: sticky) {
        position: sticky;
    }

    &--hided {
        transform: translateY(100%);
        opacity: 0;
        visibility: hidden;
    }

    .embed-responsive {
        max-width: 10rem;
        transition: transform .4s ease;

        @include media-breakpoint-up(lg) {
            max-width: 12rem;
        }

        &:hover {
            transform: scale(.98);
        }

        &:active,
        &:focus {
            transform: scale(.94);
        }

        &:after {
            position: absolute;
            display: block;
            width: 2rem;
            height: 2rem;
            top: 50%;
            left: 50%;
            margin-top: -1rem;
            margin-left: -1rem;
            background-image: url('../vectors/icon-play.svg');
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: center;
            content: '';
        }
    }

    &__title {
        font-size: $display5-size;

        @include media-breakpoint-up(md) {
            font-size: $display4-size;
        }
    }

    &__live-text {
        font-size: 1rem;

        @include media-breakpoint-up(md) {
            font-size: $display5-size;
        }

        &__icon {
            width: .8rem;
            height: .8rem;
            margin-right: .8rem;
            animation: pulse 1.5s ease infinite;
        }
    }

    &__close {
        font-size: 2rem;
        text-shadow: none;
        transition: color .4s ease;
    }

    a, a:hover{
        color: #fff
    }
}

.icon-alert {
    width: 2rem;
}

.unsubscribe-label {
    display: none;

    @media (pointer: coarse) {
        display: block;
    }
}

.welcome--special-event,
.welcome--live-show {
    .embed-responsive:before {
        padding-top: 42.85%;

        @include media-breakpoint-up(lg) {
            padding-top: 33%;
        }
    }
}

.slider--faces.no-slider{
    .swiper-wrapper{
        justify-content: center;
        .swiper-slide{
            flex-shrink: 1;
            @include media-breakpoint-up(lg) {
                max-width: 20%;
            }
        }
    }
}

.schedule__item__container:not(:first-child){
    border-top: 1px solid #dee2e6;
}


